import React from "react"
import styled from "styled-components"

const Banner = ({ title, info, children }) => {
  return (
    <BannerStyle>
      <h1>{title}</h1>
      <p>{info}</p>
      {children}
    </BannerStyle>
  )
}

const BannerStyle = styled.div`
  min-width: 100%;
  text-align: center;
  letter-spacing: var(--mainSpacing);
  color: white;
  font-size: 12px;
  position: relative;
  h1 {
    font-size: 3.3rem;
    text-transform: uppercase;
    margin-bottom: 2rem;
    padding: 0 1rem;
    letter-spacing: 6px;
  }
  p {
    display: none;
    width: 85%;
    margin: 0 auto;
    margin-bottom: 2rem;
  }
  @media screen and (min-width: 768px) {
    font-size: 1rem;
    h1 {
      font-size: 4.5rem;
    }
    p {
      display: flex;
      width: 70%;
    }
  }
`

export default Banner
